<template>
  <div>
    <a-table
      ref="table"
      :columns="columns"
      :rowKey="row => row.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :bordered="false"
      size="small"
      :scroll="{ x: 800}"
      class="atable"
    >
      <span
        slot="status"
        slot-scope="text"
      >
        <span v-if="text==1">任务巡检</span>
        <span v-else-if="text==2">随机抽查</span>
        <span v-else-if="text==3">整改复检</span>
        <span v-else>其它</span>
      </span>

      <span
        slot="fiskFlag"
        slot-scope="text"
      >
        <span v-if="text==1">有隐患</span>
        <span v-else-if="text==2">无隐患</span>
        <span v-else>未知</span>
      </span>

      <span
        slot="riskLevel"
        slot-scope="text"
      >
        <span v-if="text==1">一般隐患</span>
        <span v-else-if="text==2">严重隐患</span>
        <span v-else-if="text==3">较大隐患</span>
        <span v-else-if="text==4">重大隐患</span>
        <span v-else>未知</span>
      </span>

      <span
        slot="riskType"
        slot-scope="text"
      >
        <span v-if="text==1">人员</span>
        <span v-else-if="text==2">机械</span>
        <span v-else-if="text==3">材料</span>
        <span v-else-if="text==4">制度</span>
        <span v-else-if="text==5">环境</span>
        <span v-else>未知</span>
      </span>

      <!-- <span
                  slot="resourceId"
                  slot-scope="text"
                >
                  <a
                    v-if="text"
                    @click="showImgList(text)"
                  >查看图片</a>
                  <a v-else>无</a>
                </span> -->
    </a-table>
  </div>
</template>

<script>
const columns = [
//   { title: '所属项目', dataIndex: 'ProName', width: '10%', align: 'center'},
  { title: '巡检点', dataIndex: 'PointName', width: '7%', align: 'center'},
  // { title: '任务Id', dataIndex: 'TaskId', width: '10%', align: 'center'},
  { title: '状态', dataIndex: 'Status', width: '7%', align: 'center', scopedSlots: { customRender: 'status'}},
  { title: '隐患标记', dataIndex: 'RiskFlag', width: '7%', align: 'center', scopedSlots: { customRender: 'fiskFlag' }},
  // { title: '隐患级别', dataIndex: 'RiskLevel', width: '10%', align: 'center', scopedSlots: { customRender: 'riskLevel' }},
  // { title: '隐患类别', dataIndex: 'RiskType', width: '10%', align: 'center', scopedSlots: { customRender: 'riskType' }},
  // { title: '现场图片', dataIndex: 'ResourceId', width: '10%', align: 'center', scopedSlots: { customRender: 'resourceId' } },
  { title: '整改次数', dataIndex: 'RectifyTimes', width: '5%', align: 'center'},
  { title: '限期整改', dataIndex: 'RectifyEndDate', width: '10%', align: 'center'},
  { title: '检查人', dataIndex: 'CheckUserName', width: '10%', align: 'center'},
  { title: '检查时间', dataIndex: 'CheckDate', width: '10%', align: 'center'},
  // { title: '责任人', dataIndex: 'LiableUserName', width: '10%', align: 'center'},
  { title: '责任单位', dataIndex: 'LiableOrgName', width: '10%', align: 'center'},
]

const dataX = [
  {Id:1,ProName:'项目1',PointName:'点位1',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-12 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-11 11:11:11',LiableOrgName:'责任公司1'},
  {Id:2,ProName:'项目2',PointName:'点位2',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-14 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-12 11:11:11',LiableOrgName:'责任公司2'},
  {Id:3,ProName:'项目3',PointName:'点位3',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-15 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-13 11:11:11',LiableOrgName:'责任公司3'},
  {Id:4,ProName:'项目4',PointName:'点位4',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-16 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-14 11:11:11',LiableOrgName:'责任公司4'},
  {Id:5,ProName:'项目5',PointName:'点位5',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-17 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-15 11:11:11',LiableOrgName:'责任公司5'},
  {Id:6,ProName:'项目6',PointName:'点位6',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-18 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-16 11:11:11',LiableOrgName:'责任公司6'},
  {Id:7,ProName:'项目7',PointName:'点位7',Status:1,RiskFlag:1,RectifyTimes:1,RectifyEndDate:'2020-11-19 11:11:11',CheckUserName:'安全员1',CheckDate:'2020-11-17 11:11:11',LiableOrgName:'责任公司7'},
]
moment.locale("zh-cn");
import moment from "moment";
// import ProInfoCache from "@/utils/cache/ProInfoCache"

export default {
  props:{
    type:{
      type:Number,
      default:1
    },
    proId:{
      type:String,
      default:'test'
    }
  },
  data(){
    return{
      //表格数据
      data: [],
      dataX,
      pagination: {
        current: 1,
        pageSize: 4,
        showTotal: (total, range) => `总数:${total} 当前:${range[0]}-${range[1]}`
      },
      filters: {},
      sorter: { field: 'Id', order: 'desc' },
      columns,
      queryParam: {ProId:this.proId,RiskFlag:1,PatrolType:1},//PatrolType 1安全，2质量

      loading:true,
      pro:{},
    }
  },
  mounted(){
    // this.pro = ProInfoCache.getProInfo()
    this.load()
  },
  methods:{
    load(){
      this.getDataList()
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination }
      this.filters = { ...filters }
      this.sorter = { ...sorter }
      this.getDataList()
    },
    /**获取已检查但未通过安全/质量 */
    getDataList() {
      this.queryParam.ProId = this.proId
      this.queryParam.PatrolType=this.type
      // console.info(this.queryParam)
      this.loading = true
      if(this.proId=='test'){
        this.data = this.dataX
        this.loading = false
        return
      }
      this.$http
        .post('/Patrol/PatrolRecord/GetDataFullList', {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || 'Id',
          SortType: this.sorter.order,
          Search: this.queryParam,
          ... this.filters
        })
        .then(resJson => {
          this.loading = false
          if(resJson.Success){
            this.data = resJson.Data
            const pagination = { ...this.pagination }
            pagination.total = resJson.Total
            this.pagination = pagination
          }
        })
    },
  }
}
</script>

<style lang="scss">
.atable {
  // background-color: white;

  .title {
    color: white;
  }
  .titleName {
    color: white;
    font-size: 10px;
  }
  //修改表头文字、背景颜色
  .ant-table-thead > tr > th {
    color: white;
    background: transparent;
  }

  //修改列表文字、背景颜色
  .ant-table-tbody > tr > td {
    color: white;
    background: transparent;
  }
  //列表鼠标悬浮状态
  table tbody tr:hover > td {
    background-color: #00b9ff !important;
  }

  //分页器
  .ant-pagination {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 5px;
    padding: 0;
    color: white;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
  }
  a {
    color: white;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: Arial;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: transparent;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #193b5c;
    border-color: #1890ff;
  }

  //空背景
  .ant-table-placeholder {
    position: relative;
    z-index: 1;
    margin-top: -1px;
    padding: 16px 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    text-align: center;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    .ant-empty-description {
      color: white;
    }
  }
}
</style>